import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { JobFeature } from "../components/job-components"
import SuccessStory from "../components/success-story"

const SuccessStoryLooom = ({ data }) => {
  return (
    <SuccessStory
      title="GDPR-compliant AR/VR applications by using Celantur Cloud API"
      customerName="Looom"
      customerLocation="Austria"
      customerDescription="Looom, an owner-managed digitalization agency based in Linz and Vienna, is renowned for its diverse team of experts specializing in 3D Indoor Mapping, 3D scanning, CAD, Digital Twin Consulting, Digital Factory Consulting, Virtual Reality, Augmented Reality, and 3D Animation."
      customerIndustries="Digital Twins, Virtual/Augmented Reality"
      link="https://looom.at/"
      logo=""
      style={{
        background: "url(/images/looom-pano-garage.jpg) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <h2 className="my-3">Challenge</h2>
      <p>
        <strong>
          In the early stages of Looom's data protection journey, they encountered a significant challenge. They needed
          a reliable and efficient solution to blur personal information contained in images before delivering it to
          their clients - especially when running indoor scanning campaigns with NavVis M6 and NavVis VLX. As the volume
          of image data continued to grow, manual blurring became impractical and time-consuming.
        </strong>
      </p>

      <figure className="figure">
        <Img fluid={data.looomPanoGarage.childImageSharp.fluid} className="rounded my-3" />
        <figcaption className="figure-caption">Anonymized panorama image of a parking garage. (c) Looom GmbH</figcaption>
      </figure>

      <h2 className="my-3">Solution</h2>
      <p>
        To address this challenge, Looom initially turned to Celantur's easy-to-use cloud service, which provided a
        straightforward method for blurring personal information in images. However, as their data grew, Looom
        recognized the need for an integrated and scalable solution.
      </p>

      <p>
        Looom made the strategic decision to switch to Celantur Cloud API, a robust REST-based web solution. This
        integration allowed Looom to blur faces, license plates, persons, and vehicles on images within seconds using a
        simple REST call. Celantur Cloud API seamlessly fits into Looom's workflow, providing automation and scalability
        without compromising on data privacy.
      </p>

      <figure className="figure">
        <blockquote className="blockquote">
          <cite>
            "When digitalizing production sites and office spaces for our clients, it’s difficult to avoid having people
            present at the scan location, leaving us with thousands of panoramic images to anonymize. Thankfully,
            Celantur has provided us with a dependable and quick solution, allowing us to swiftly blur all personal
            information on all images with ease."
          </cite>
        </blockquote>
        <figcaption className="figure-caption"> - Arnold Wenger | CEO, Looom GmbH</figcaption>
      </figure>

      <figure className="figure">
        <Img fluid={data.looomPanoTrainstation.childImageSharp.fluid} className="rounded my-3" />
        <figcaption className="figure-caption">Anonymized panorama image of a train station in Austria. (c) Looom GmbH</figcaption>
      </figure>

      <h2 className="my-3">Celantur's contribution</h2>
      <JobFeature>Offering an easy-to-use, scalable and web-based image anonymization API.</JobFeature>
      <JobFeature>Enabling more business and upselling-opportunities for Looom.</JobFeature>
    </SuccessStory>
  )
}

export default SuccessStoryLooom

export const query = graphql`
  query {
    looomPanoTrainstation: file(relativePath: { eq: "looom-pano-trainstation.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    looomPanoGarage: file(relativePath: { eq: "looom-pano-garage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
